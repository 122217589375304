import classNames from 'classnames';
import React, { useCallback, useEffect, useId, useRef, useState } from 'react';

import headerLogo from '@/assets/images/header-logo-new.svg';
import { Button } from '@/design-system/atoms/Button';
import { ContentContainer } from '@/design-system/atoms/ContentContainer';
import { Typeahead } from '@/design-system/atoms/Forms/Typeahead';
import { Grid, GridColumn } from '@/design-system/atoms/Grid';
import { LazyIconSearch } from '@/design-system/atoms/Icons/IconSearch/Lazy';
import { LazyIconX } from '@/design-system/atoms/Icons/IconX/Lazy';
import { Image } from '@/design-system/atoms/Image';
import { Text } from '@/design-system/atoms/Text';
import { Title } from '@/design-system/atoms/Title';
import { useWindowSize } from '@/hooks/useWindowSize';
import { trackInteraction } from '@/utils/analytics';
import { scrollControl } from '@/utils/scrollControl';
import window from '@/utils/window';

import { useHeaderContext } from '../../contexts/HeaderContext';
import { headerPropTypes } from '../../headerPropTypes';
import styles from './DesktopHeader.module.scss';

const DesktopHeader = ({
    id,
    logoHref,
    searchLabelOverride = 'Search',
    searchAriaLabel = 'Open Global Site Search',
    mainNavAriaLabel = 'Open Main Navigation',
    closeNavAriaLabel = 'Close Main Navigation',
    backNavAriaLabel = 'Back to Main Navigation',
    navigationItems,
    search,
    closeSearchAriaLabel = 'Close Global Site Search',
    primaryCta,
    utilityNavigationItems,
    isInEditor,
    coveo,
    searchResultsPage,
    disableCSR,
    hideLanguageSelector,
    onLanguageChange,
    isActive,
    ...props
}) => {
    const key = useId();
    const searchId = useId();
    const { width } = useWindowSize();

    const languageDropdownRef = useRef();
    const headerRef = useRef();
    const navListRef = useRef();
    const flyoutRefs = useRef(navigationItems?.map(React.createRef));
    const searchButtonRef = useRef();
    const searchTypeaheadRef = useRef();
    const languageRefs = useRef([]);
    const [url, setUrl] = useState('');
    const [urlPathname, setUrlPathname] = useState('');
    const [urlSearch, setUrlSearch] = useState('');

    const [languageDropdownOpen, setLanguageDropdownOpen] = useState(false);
    const [openNav, setOpenNav] = useState(null);
    const [maxHeight, setMaxHeight] = useState(0);
    const [leftPosition, setLeftPosition] = useState(0);
    const [searchOpen, setSearchOpen] = useState(false);
    const [languageLabel, setLanguageLabel] = useState('English');

    const { searchValue, suggestions, handleSetSearchValue, handleSelectSuggestion, submitForm } =
        useHeaderContext();

    const ToggleDropdown = useCallback(() => {
        if (languageDropdownOpen) {
            setLanguageDropdownOpen(false);
            languageDropdownRef?.current?.setAttribute('inert', '');
        } else {
            languageDropdownRef?.current?.removeAttribute('inert');
            setLanguageDropdownOpen(true);
        }
    }, [languageDropdownOpen, setLanguageDropdownOpen, languageDropdownRef]);

    const ToggleSearch = useCallback(
        (skipFocus) => {
            if (searchOpen) {
                setOpenNav(null);
                setSearchOpen(false);
                scrollControl.enableScroll();
                if (!skipFocus) {
                    setTimeout(() => {
                        searchButtonRef?.current.focus();
                    }, 300);
                }

                // Analytics
                trackInteraction({
                    eventName: 'search',
                    componentName: 'Navigation',
                    componentTitle: 'navigation',
                    interactionType: 'close',
                    selector: 'search',
                    searchTerm: searchValue,
                });
            } else {
                setOpenNav(null);
                setSearchOpen(true);
                scrollControl.disableScroll();
                setTimeout(() => {
                    searchTypeaheadRef?.current.focus();
                }, 300);
            }
        },
        [searchOpen, setOpenNav, setSearchOpen, searchButtonRef, searchTypeaheadRef, searchValue],
    );

    const ToggleFlyOut = useCallback(
        (activeIndex) => {
            if (openNav === activeIndex) {
                setOpenNav(null);
                setMaxHeight(0);
            } else {
                setMaxHeight(
                    flyoutRefs?.current[activeIndex].current.getBoundingClientRect().height,
                );
                setOpenNav(activeIndex);
            }
        },
        [openNav, setOpenNav, flyoutRefs, setMaxHeight],
    );

    const resetHeader = useCallback(() => {
        if (searchOpen) {
            ToggleSearch(true);
        }

        if (languageDropdownOpen) {
            ToggleDropdown();
        }
        setOpenNav(null);
        setMaxHeight(0);
    }, [setOpenNav, setMaxHeight, ToggleDropdown, ToggleSearch, searchOpen, languageDropdownOpen]);

    const onClickFocus = useCallback(
        (event) => {
            const isMobileHeader = event.target?.closest('header[data-header-type="mobile"]');
            if (!isMobileHeader && !headerRef?.current.contains(event.target)) {
                setSearchOpen(false);
                scrollControl.enableScroll();
                setOpenNav(null);
                setMaxHeight(0);
                if (languageDropdownOpen) {
                    setLanguageDropdownOpen(false);
                    languageDropdownRef?.current?.setAttribute('inert', '');
                }
            }
        },
        [headerRef, setOpenNav, setMaxHeight, languageDropdownOpen],
    );

    const escKeyListen = useCallback(
        (event) => {
            if (event.key === 'Escape') {
                setOpenNav(null);
                setMaxHeight(0);
                if (languageDropdownOpen) {
                    setLanguageDropdownOpen(false);
                    languageDropdownRef?.current?.setAttribute('inert', '');
                }
            }
        },
        [setOpenNav, setMaxHeight, languageDropdownOpen],
    );

    const handleLogoClick = useCallback(() => {
        trackInteraction({
            componentName: 'Navigation',
            componentTitle: 'navigation',
            interactionType: 'nav_logo',
            actionLabel: 'Atlantic Health System Homepage',
        });
    }, []);

    useEffect(() => {
        window.addEventListener('click', onClickFocus);
        window.addEventListener('focusin', onClickFocus);
        window.addEventListener('keyup', escKeyListen);
        window.addEventListener('keydown', escKeyListen);

        if (!languageDropdownOpen) {
            languageDropdownRef?.current?.setAttribute('inert', '');
        }

        return () => {
            window.removeEventListener('click', onClickFocus);
            window.removeEventListener('focusin', onClickFocus);
            window.removeEventListener('keyup', escKeyListen);
            window.removeEventListener('keydown', escKeyListen);
        };
    });

    useEffect(() => {
        var styles =
            navListRef?.current.currentStyle || window.getComputedStyle(navListRef?.current);

        if (styles.marginLeft === '0px') {
            setLeftPosition('20px');
        } else {
            setLeftPosition(styles.marginLeft);
        }
    }, [setLeftPosition, width]);

    useEffect(() => {
        languageRefs?.current.forEach((ref) => {
            if (ref?.getAttribute('active-language') > -1) {
                setLanguageLabel(ref.getAttribute('label-setter'));
            }
        });
    }, []);

    useEffect(() => {
        if (!isActive) {
            resetHeader();
        }
    }, [isActive, resetHeader]);

    useEffect(() => {
        setUrl(window.location.origin);
        setUrlPathname(window.location.pathname);
        setUrlSearch(window.location.search);
    }, [setUrl, setUrlPathname, setUrlSearch]);

    return (
        <header className={styles[`desktop-header`]} ref={headerRef} {...props}>
            <div
                className={classNames(
                    styles[`desktop-header__overlay`],
                    (searchOpen || openNav !== null) && styles[`desktop-header__overlay--active`],
                    isInEditor && styles[`desktop-header__overlay--editor`],
                )}
            />
            {/* Start Utility Nav */}
            {Array.isArray(utilityNavigationItems) && utilityNavigationItems?.length > 0 && (
                <nav
                    aria-label="Utility Navigation"
                    role="menu"
                    className={classNames(
                        styles[`desktop-header__utility-wrapper`],
                        styles[`desktop-header--bg-linen`],
                    )}
                >
                    <ContentContainer className={styles[`desktop-header__utility`]}>
                        {Array.isArray(utilityNavigationItems) &&
                            utilityNavigationItems?.map((item, index) => {
                                return (
                                    <div
                                        className={styles[`desktop-header__utility-nav`]}
                                        key={`language-dropdown-${index}`}
                                    >
                                        {item?.dropdown && !hideLanguageSelector && (
                                            <div
                                                className={
                                                    styles[`desktop-header__dropdown-wrapper`]
                                                }
                                            >
                                                <Button
                                                    key={item?.label + index + key}
                                                    type="button"
                                                    buttonStyle={Button.STYLE.UTILITY}
                                                    label={languageLabel}
                                                    aria-label={`Change site Language, currently ${languageLabel}`}
                                                    iconComponent={[
                                                        { iconName: item?.iconName },
                                                        {
                                                            iconName: 'chevron-down',
                                                            iconProps: {
                                                                className: classNames(
                                                                    styles[
                                                                        `desktop-header__chevron`
                                                                    ],
                                                                    languageDropdownOpen &&
                                                                        styles[
                                                                            `desktop-header__chevron--open`
                                                                        ],
                                                                ),
                                                            },
                                                        },
                                                    ]}
                                                    id={`utility-toggle-${index}`}
                                                    aria-controls={`utility-dropdown-${index}`}
                                                    aria-haspopup="true"
                                                    aria-expanded={languageDropdownOpen}
                                                    onClick={ToggleDropdown}
                                                    role="menuitem"
                                                />
                                                <ul
                                                    ref={languageDropdownRef}
                                                    className={classNames(
                                                        styles[`desktop-header__utility-dropdown`],
                                                        languageDropdownOpen &&
                                                            styles[
                                                                `desktop-header__utility-dropdown--open`
                                                            ],
                                                    )}
                                                    id={`utility-dropdown-${index}`}
                                                    role="menu"
                                                    aria-labelledby={`utility-toggle-${index}`}
                                                >
                                                    {Array.isArray(item?.children) &&
                                                        item?.children?.map((child, index) => {
                                                            const normalizedLabel = (label) => {
                                                                return label
                                                                    .normalize('NFD')
                                                                    .replace(/[\u0300-\u036f]/g, '')
                                                                    .toLowerCase();
                                                            };

                                                            const href = onLanguageChange
                                                                ? undefined
                                                                : child?.href +
                                                                  urlPathname +
                                                                  urlSearch;

                                                            const onClick = onLanguageChange
                                                                ? (e) => {
                                                                      onLanguageChange(
                                                                          e,
                                                                          child?.label.toLowerCase(),
                                                                      );
                                                                      ToggleDropdown();
                                                                  }
                                                                : undefined;

                                                            return (
                                                                <li
                                                                    role="none"
                                                                    key={`language-select-${index}`}
                                                                >
                                                                    <Button
                                                                        ref={(el) =>
                                                                            (languageRefs.current =
                                                                                [
                                                                                    ...languageRefs.current,
                                                                                    el,
                                                                                ])
                                                                        }
                                                                        key={
                                                                            child?.label +
                                                                            index +
                                                                            key
                                                                        }
                                                                        href={href}
                                                                        onClick={onClick}
                                                                        buttonStyle={
                                                                            Button.STYLE.UTILITY
                                                                        }
                                                                        label={child?.label}
                                                                        active-language={url.indexOf(
                                                                            normalizedLabel(
                                                                                child?.label,
                                                                            ),
                                                                        )}
                                                                        label-setter={child?.label}
                                                                        aria-label={`Change site language to ${child?.label}`}
                                                                        role="menuitem"
                                                                        className={
                                                                            styles[
                                                                                `desktop-header__language-option`
                                                                            ]
                                                                        }
                                                                        csr={false}
                                                                        analytics={{
                                                                            componentName:
                                                                                'Navigation',
                                                                            componentTitle:
                                                                                'utility_nav',
                                                                            interactionType:
                                                                                'dropdown',
                                                                        }}
                                                                    />
                                                                </li>
                                                            );
                                                        })}
                                                </ul>
                                            </div>
                                        )}
                                        {!item?.dropdown && (
                                            <Button
                                                key={item?.label + index + key}
                                                href={item?.href}
                                                buttonStyle={Button.STYLE.UTILITY}
                                                iconName={item?.iconName}
                                                label={item?.label}
                                                aria-label={item?.label}
                                                role="menuitem"
                                                analytics={{
                                                    componentName: 'Navigation',
                                                    componentTitle: 'utility_nav',
                                                    interactionType: 'internal_link',
                                                }}
                                                csr={disableCSR ? !disableCSR : item?.csr}
                                            />
                                        )}
                                    </div>
                                );
                            })}
                    </ContentContainer>
                </nav>
            )}
            {/* End Utility Nav */}
            {/* Start Main Nav */}
            <nav
                aria-label="Main Navigation"
                role="menu"
                className={classNames(
                    styles[`desktop-header__main-nav`],
                    styles[`desktop-header--bg-white`],
                )}
            >
                <ContentContainer className={styles[`desktop-header__main-nav-container`]}>
                    <a
                        href={logoHref}
                        className={styles[`desktop-header__logo`]}
                        aria-label="Atlantic Health System Homepage"
                        role="menuitem"
                        onClick={handleLogoClick}
                        data-trigger={'a'}
                    >
                        <Image
                            src={headerLogo}
                            decorative
                            className={styles[`desktop-header__logo--image`]}
                        />
                    </a>
                    <div className={styles[`desktop-header__search-nav`]}>
                        <ul
                            className={styles[`desktop-header__nav-items`]}
                            role="none"
                            ref={navListRef}
                            {...(searchOpen ? { inert: '' } : {})}
                        >
                            {Array.isArray(navigationItems) &&
                                navigationItems?.map((item, index) => {
                                    return (
                                        <li
                                            key={`nav-item-${index}`}
                                            className={styles[`desktop-header__nav-item`]}
                                        >
                                            <Button
                                                buttonStyle={Button.STYLE.MAIN_NAV}
                                                className={classNames(
                                                    styles[`desktop-header__nav-item-button`],
                                                    openNav === index &&
                                                        styles[
                                                            `desktop-header__nav-item-button--active`
                                                        ],
                                                )}
                                                label={item?.parent}
                                                iconComponent={[
                                                    {
                                                        iconName: 'chevron-down',
                                                        iconProps: {
                                                            className: classNames(
                                                                styles[`desktop-header__chevron`],
                                                                openNav === index &&
                                                                    styles[
                                                                        `desktop-header__chevron--open`
                                                                    ],
                                                            ),
                                                        },
                                                    },
                                                ]}
                                                aria-controls={`nav-item-${index}`}
                                                aria-haspopup="true"
                                                aria-expanded={false}
                                                role="menuitem"
                                                onClick={() => ToggleFlyOut(index)}
                                                analytics={{
                                                    componentName: 'Navigation',
                                                    componentTitle: item?.parent,
                                                    interactionType: 'navigation',
                                                }}
                                            />
                                            <div
                                                role="none"
                                                className={classNames(
                                                    styles[`desktop-header__flyout`],
                                                    openNav === index &&
                                                        styles[`desktop-header__flyout--open`],
                                                )}
                                                style={{ '--max-height': `${maxHeight}px` }}
                                                {...(openNav !== index ? { inert: '' } : {})}
                                            >
                                                <div ref={flyoutRefs?.current[index]}>
                                                    <Grid
                                                        className={styles[`desktop-header__grid`]}
                                                    >
                                                        <GridColumn
                                                            className={
                                                                styles[`desktop-header__intro`]
                                                            }
                                                            colSpan={{ sm: 4, md: 6, lg: 3 }}
                                                        >
                                                            <Title
                                                                variant={Title.VARIANT.H5}
                                                                tag={Title.TAG.DIV}
                                                                content={item?.title}
                                                            />
                                                            {item?.description && (
                                                                <Text
                                                                    variant={Text.VARIANT.T3}
                                                                    tag={Text.TAG.P}
                                                                    content={item.description}
                                                                />
                                                            )}
                                                        </GridColumn>
                                                        <GridColumn
                                                            colSpan={{ sm: 4, md: 6, lg: 5 }}
                                                        >
                                                            <div
                                                                className={classNames(
                                                                    styles[`desktop-header__list`],
                                                                )}
                                                            >
                                                                {Array.isArray(item?.children) &&
                                                                    item?.children.map(
                                                                        (child, index) => {
                                                                            return (
                                                                                <Button
                                                                                    {...child}
                                                                                    id={`nav-item-${index}`}
                                                                                    key={`nav-item-${index}`}
                                                                                    href={
                                                                                        child?.href
                                                                                    }
                                                                                    buttonStyle={
                                                                                        Button.STYLE
                                                                                            .TERTIARY
                                                                                    }
                                                                                    label={
                                                                                        child?.label
                                                                                    }
                                                                                    aria-label={
                                                                                        child?.ariaLabel
                                                                                    }
                                                                                    role="menuitem"
                                                                                    className={
                                                                                        styles[
                                                                                            `desktop-header__nav-item`
                                                                                        ]
                                                                                    }
                                                                                    analytics={{
                                                                                        componentName:
                                                                                            'Navigation',
                                                                                        componentTitle:
                                                                                            item
                                                                                                ?.parent
                                                                                                ?.label,
                                                                                        interactionType:
                                                                                            'navigation',
                                                                                    }}
                                                                                    csr={
                                                                                        disableCSR
                                                                                            ? !disableCSR
                                                                                            : child?.csr
                                                                                    }
                                                                                    onClick={
                                                                                        resetHeader
                                                                                    }
                                                                                />
                                                                            );
                                                                        },
                                                                    )}
                                                                {Array.isArray(
                                                                    item?.categorized,
                                                                ) && (
                                                                    <>
                                                                        {item?.categorized.map(
                                                                            (item, index) => {
                                                                                return (
                                                                                    <div
                                                                                        className={classNames(
                                                                                            styles[
                                                                                                `desktop-header__list`
                                                                                            ],
                                                                                            styles[
                                                                                                `desktop-header__list--categorized`
                                                                                            ],
                                                                                        )}
                                                                                        key={index}
                                                                                    >
                                                                                        <Text
                                                                                            variant={
                                                                                                Text
                                                                                                    .VARIANT
                                                                                                    .E1
                                                                                            }
                                                                                            tag={
                                                                                                Text
                                                                                                    .TAG
                                                                                                    .P
                                                                                            }
                                                                                            content={
                                                                                                item?.label
                                                                                            }
                                                                                            className={classNames(
                                                                                                styles[
                                                                                                    `desktop-header__category-label`
                                                                                                ],
                                                                                            )}
                                                                                        />
                                                                                        {Array.isArray(
                                                                                            item?.children,
                                                                                        ) && (
                                                                                            <>
                                                                                                {item?.children.map(
                                                                                                    (
                                                                                                        child,
                                                                                                        index,
                                                                                                    ) => {
                                                                                                        return (
                                                                                                            <Button
                                                                                                                {...child}
                                                                                                                id={`nav-item-${index}`}
                                                                                                                key={`nav-item-${index}`}
                                                                                                                href={
                                                                                                                    child?.href
                                                                                                                }
                                                                                                                buttonStyle={
                                                                                                                    Button
                                                                                                                        .STYLE
                                                                                                                        .TERTIARY
                                                                                                                }
                                                                                                                label={
                                                                                                                    child?.label
                                                                                                                }
                                                                                                                aria-label={
                                                                                                                    child?.label
                                                                                                                }
                                                                                                                role="menuitem"
                                                                                                                className={
                                                                                                                    styles[
                                                                                                                        `desktop-header__nav-item`
                                                                                                                    ]
                                                                                                                }
                                                                                                                analytics={{
                                                                                                                    componentName:
                                                                                                                        'Navigation',
                                                                                                                    componentTitle:
                                                                                                                        item
                                                                                                                            ?.parent
                                                                                                                            ?.label,
                                                                                                                    interactionType:
                                                                                                                        'navigation',
                                                                                                                }}
                                                                                                                csr={
                                                                                                                    disableCSR
                                                                                                                        ? !disableCSR
                                                                                                                        : child?.csr
                                                                                                                }
                                                                                                            />
                                                                                                        );
                                                                                                    },
                                                                                                )}
                                                                                            </>
                                                                                        )}
                                                                                    </div>
                                                                                );
                                                                            },
                                                                        )}
                                                                    </>
                                                                )}
                                                            </div>
                                                        </GridColumn>
                                                        <GridColumn
                                                            colSpan={{ sm: 4, md: 6, lg: 4 }}
                                                        >
                                                            {/* menu card */}
                                                            {item?.menuCardComponent}
                                                        </GridColumn>
                                                    </Grid>
                                                </div>
                                            </div>
                                        </li>
                                    );
                                })}
                        </ul>
                        <div
                            className={styles[`desktop-header__actions`]}
                            {...(searchOpen ? { inert: '' } : {})}
                        >
                            {/* Primary CTA */}
                            {primaryCta && (
                                <div className={styles[`desktop-header__primary-cta`]} role="none">
                                    <Button
                                        {...primaryCta}
                                        buttonStyle={Button.STYLE.PRIMARY}
                                        role="menuitem"
                                        analytics={{
                                            componentName: 'Navigation',
                                            componentTitle: 'navigation',
                                        }}
                                        csr={disableCSR ? !disableCSR : primaryCta?.csr}
                                    />
                                </div>
                            )}
                            {/* Search */}
                            <Button
                                ref={searchButtonRef}
                                ariaLabel={searchAriaLabel}
                                buttonStyle={Button.STYLE.TERTIARY}
                                iconComponent={LazyIconSearch}
                                className={styles[`desktop-header__search`]}
                                role="menuitem"
                                aria-expanded={searchOpen}
                                aria-haspopup="true"
                                aria-controls={searchId}
                                onClick={ToggleSearch}
                            />
                        </div>
                        {/* Search Bar */}
                        <form
                            id={searchId}
                            className={classNames(
                                styles[`desktop-header__search-bar`],
                                searchOpen && styles[`desktop-header__search-bar--open`],
                            )}
                            style={{ '--left-position': leftPosition }}
                            {...(!searchOpen ? { inert: '' } : {})}
                            onSubmit={submitForm}
                            data-trigger="search"
                        >
                            <div className={styles[`desktop-header__search-bar-wrapper`]}>
                                <Typeahead
                                    ref={searchTypeaheadRef}
                                    label={searchLabelOverride}
                                    buttonProps={{
                                        type: 'submit',
                                        iconComponent: LazyIconSearch,
                                    }}
                                    items={suggestions}
                                    inputValue={searchValue}
                                    onInputChange={handleSetSearchValue}
                                    onSelectionItemChange={handleSelectSuggestion}
                                    wrapperClassName={styles[`desktop-header__search-bar-input`]}
                                    popoverClassName={styles[`desktop-header__search-bar-popover`]}
                                />
                                <Button
                                    ariaLabel={closeSearchAriaLabel}
                                    buttonStyle={Button.STYLE.TERTIARY}
                                    iconComponent={LazyIconX}
                                    className={styles[`desktop-header__close`]}
                                    type="button"
                                    onClick={ToggleSearch}
                                />
                            </div>
                        </form>
                    </div>
                </ContentContainer>
            </nav>
            {/* End Main Nav */}
        </header>
    );
};

DesktopHeader.propTypes = {
    ...headerPropTypes,
};

export default DesktopHeader;
